import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

interface Consultant {
  id: string;
  name: string;
  email: string;
  timezone: string;
  profilePicture?: string; // URL of the profile picture
  description?: string;
  experience?: string[];
  achievements?: string[];
  linkedIn?: string; // List of achievements
  personalWebsite?: string; // List of achievements
  createdAt: string;
}

interface ConsultantState {
  consultants: Consultant[];
  loading: boolean;
  error: string | null;
}

const initialState: ConsultantState = {
  consultants: [],
  loading: false,
  error: null,
};

// Async thunk to handle fetching all consultants
export const fetchConsultants = createAsyncThunk(
  "consultants/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/consultants");
      console.log(response);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

// Async thunk to handle creating a new consultant
export const createConsultant = createAsyncThunk(
  "consultants/create",
  async (
    formData: FormData, // Expecting FormData here
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post("/consultants", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure multipart header is set
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Creation failed"
      );
    }
  }
);

// Async thunk to handle updating a consultant
export const updateConsultant = createAsyncThunk(
  "consultants/update",
  async (
    payload: {
      id: string;
      formData: FormData; // Expecting formData here
    },
    { rejectWithValue }
  ) => {
    try {
      // Send the FormData directly via Axios
      const response = await axiosInstance.patch(
        `/consultants/${payload.id}`,
        payload.formData, // FormData object is sent here
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure multipart header is set
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Update failed");
    }
  }
);
// Async thunk to handle deleting a consultant
export const deleteConsultant = createAsyncThunk(
  "consultants/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/consultants/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Deletion failed"
      );
    }
  }
);

const consultantSlice = createSlice({
  name: "consultants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchConsultants.fulfilled,
        (state, action: PayloadAction<Consultant[]>) => {
          state.consultants = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchConsultants.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createConsultant.fulfilled,
        (state, action: PayloadAction<Consultant>) => {
          state.consultants.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(
        createConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // .addCase(updateConsultant.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(
      //   updateConsultant.fulfilled,
      //   (state, action: PayloadAction<Consultant>) => {
      //     const index = state.consultants.findIndex(
      //       (consultant) => consultant.id === action.payload.id
      //     );
      //     if (index !== -1) {
      //       state.consultants[index] = action.payload;
      //     }
      //     state.loading = false;
      //   }
      // )
      // .addCase(
      //   updateConsultant.rejected,
      //   (state, action: PayloadAction<any>) => {
      //     state.loading = false;
      //     state.error = action.payload;
      //   }
      // )
      .addCase(deleteConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteConsultant.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.consultants = state.consultants.filter(
            (consultant) => consultant.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(
        deleteConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default consultantSlice.reducer;
