import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

export interface Consultant {
  id: string;
  name: string;
  email: string;
  password: string;
  createdAt: string;
  timezone: string;
}
export interface ConsultantType {
  consultantType: any;
  id: string;
  name: string;
  description: string;
}

export interface ClientConsultant {
  id: string;
  clientId: string;
  consultantId: string;
  consultantTypes: ConsultantType[];
  consultant: Consultant;
}

interface ClientConsultantState {
  clientConsultants: ClientConsultant[];
  clientConsultantsByClientID: { [key: string]: ClientConsultant[] };
  loading: boolean;
  error: string | null;
}

const initialState: ClientConsultantState = {
  clientConsultants: [],
  clientConsultantsByClientID: {},
  loading: false,
  error: null,
};

// Async thunk to handle fetching all client consultants
export const fetchClientConsultants = createAsyncThunk(
  "clientConsultants/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/client-consultants");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

// Async thunk to handle fetching consultants by client ID
export const fetchConsultantsByClientId = createAsyncThunk(
  "clientConsultants/fetchByClientId",
  async (clientId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/client-consultants/client/${clientId}`
      );
      return { clientId, data: response.data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

// Async thunk to handle creating a new client consultant
export const createClientConsultant = createAsyncThunk(
  "clientConsultants/create",
  async (
    payload: {
      clientId: string;
      consultantId: string;
      consultantTypeIds?: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post("/client-consultants", payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Creation failed"
      );
    }
  }
);

// Async thunk to handle updating a client consultant
export const updateClientConsultant = createAsyncThunk(
  "clientConsultants/update",
  async (
    payload: {
      id: string;
      clientId?: string;
      consultantId?: string;
      consultantTypeIds?: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { id, ...updateData } = payload;
      const response = await axiosInstance.patch(
        `/client-consultants/${id}`,
        updateData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Update failed");
    }
  }
);

// Async thunk to handle deleting client consultant by id
export const deleteClientConsultantById = createAsyncThunk(
  "clientConsultants/deleteById",
  async (id: string, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/client-consultants/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Deletion failed"
      );
    }
  }
);

const clientConsultantSlice = createSlice({
  name: "clientConsultants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientConsultants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchClientConsultants.fulfilled,
        (state, action: PayloadAction<ClientConsultant[]>) => {
          state.clientConsultants = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchClientConsultants.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(fetchConsultantsByClientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchConsultantsByClientId.fulfilled,
        (
          state,
          action: PayloadAction<{ clientId: string; data: ClientConsultant[] }>
        ) => {
          state.clientConsultantsByClientID = {
            ...state.clientConsultantsByClientID,
            [action.payload.clientId]: action.payload.data,
          };
          state.loading = false;
        }
      )
      .addCase(
        fetchConsultantsByClientId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createClientConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createClientConsultant.fulfilled,
        (state, action: PayloadAction<ClientConsultant>) => {
          state.clientConsultants.push(action.payload);
          const clientConsultants =
            state.clientConsultantsByClientID[action.payload.clientId];
          if (clientConsultants) {
            clientConsultants.push(action.payload);
          } else {
            state.clientConsultantsByClientID[action.payload.clientId] = [
              action.payload,
            ];
          }
          state.loading = false;
        }
      )
      .addCase(
        createClientConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(updateClientConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateClientConsultant.fulfilled,
        (state, action: PayloadAction<ClientConsultant>) => {
          const index = state.clientConsultants.findIndex(
            (clientConsultant) => clientConsultant.id === action.payload.id
          );
          if (index !== -1) {
            state.clientConsultants[index] = action.payload;
          }
          const clientConsultants =
            state.clientConsultantsByClientID[action.payload.clientId];
          if (clientConsultants) {
            const clientIndex = clientConsultants.findIndex(
              (clientConsultant) => clientConsultant.id === action.payload.id
            );
            if (clientIndex !== -1) {
              clientConsultants[clientIndex] = action.payload;
            }
          }
          state.loading = false;
        }
      )
      .addCase(
        updateClientConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(deleteClientConsultantById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteClientConsultantById.fulfilled,
        (state, action: PayloadAction<string>) => {
          const id = action.payload;
          state.clientConsultants = state.clientConsultants.filter(
            (clientConsultant) => clientConsultant.id !== id
          );
          for (const clientId in state.clientConsultantsByClientID) {
            state.clientConsultantsByClientID[clientId] =
              state.clientConsultantsByClientID[clientId].filter(
                (clientConsultant) => clientConsultant.id !== id
              );
          }
          state.loading = false;
        }
      )
      .addCase(
        deleteClientConsultantById.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default clientConsultantSlice.reducer;
