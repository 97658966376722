import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { token, role } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  if (token) {
    let redirectPath = "/";
    switch (role) {
      case "sysadmin":
        redirectPath = "/admin/clients";
        break;
      case "consultant":
        redirectPath = "/consultant/clients";
        break;
      case "client":
        redirectPath = "/client/dashboard";
        break;
      case "member":
        redirectPath = "/member/updates";
        break;
      default:
        break;
    }

    const loginPaths = [
      "/admin/login",
      "/consultant/login",
      "/client/login",
      "/member/login",
    ];
    console.log(redirectPath);
    if (loginPaths.includes(location.pathname)) {
      return <Navigate to={redirectPath} />;
    }

    return children;
  }

  return children;
};

export default PublicRoute;
