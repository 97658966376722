import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

export interface Department {
  id: string;
  name: string;
  clientId: string;
}

interface DepartmentState {
  departments: Department[];
  loading: boolean;
  error: string | null;
}

const initialState: DepartmentState = {
  departments: [],
  loading: false,
  error: null,
};

// Async thunk for fetching departments
export const fetchDepartments = createAsyncThunk<
  Department[],
  void,
  { rejectValue: string; state: RootState }
>("departments/fetchDepartments", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch departments"
    );
  }
});

// Async thunk for creating a department
export const createDepartment = createAsyncThunk<
  Department,
  Omit<Department, "id">,
  { rejectValue: string; state: RootState }
>(
  "departments/createDepartment",
  async (newDepartment, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.post("/departments", newDepartment, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create department"
      );
    }
  }
);

// Async thunk for updating a department
export const updateDepartment = createAsyncThunk<
  Department,
  { id: string; updatedDepartment: Partial<Department> },
  { rejectValue: string; state: RootState }
>(
  "departments/updateDepartment",
  async ({ id, updatedDepartment }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.patch(
        `/departments/${id}`,
        updatedDepartment,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update department"
      );
    }
  }
);

// Async thunk for deleting a department
export const deleteDepartment = createAsyncThunk<
  string,
  string,
  { rejectValue: string; state: RootState }
>("departments/deleteDepartment", async (id, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.delete(`/departments/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return id;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete department"
    );
  }
});

// Define the slice
const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDepartments.fulfilled,
        (state, action: PayloadAction<Department[]>) => {
          state.loading = false;
          state.departments = action.payload;
        }
      )
      .addCase(
        fetchDepartments.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createDepartment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createDepartment.fulfilled,
        (state, action: PayloadAction<Department>) => {
          state.loading = false;
          state.departments.push(action.payload);
        }
      )
      .addCase(
        createDepartment.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(updateDepartment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateDepartment.fulfilled,
        (state, action: PayloadAction<Department>) => {
          state.loading = false;
          const index = state.departments.findIndex(
            (dept) => dept.id === action.payload.id
          );
          if (index !== -1) {
            state.departments[index] = action.payload;
          }
        }
      )
      .addCase(
        updateDepartment.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(deleteDepartment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteDepartment.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.departments = state.departments.filter(
            (dept) => dept.id !== action.payload
          );
        }
      )
      .addCase(
        deleteDepartment.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default departmentSlice.reducer;
