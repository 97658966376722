// src/slices/memberUpdateSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

interface Update {
  id: string;
  type: string;
  content: string;
  authorId: string;
  authorName: string; // Added field for author name
  clientId: string;
  createdAt: string;
}

interface UpdateState {
  updates: Update[];
  loading: boolean;
  error: string | null;
}

const initialState: UpdateState = {
  updates: [],
  loading: false,
  error: null,
};

// Async thunk for fetching member updates
export const fetchMemberUpdates = createAsyncThunk<
  Update[],
  void,
  { rejectValue: string; state: RootState }
>("memberUpdates/fetchUpdates", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/updates/member", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch member updates"
    );
  }
});

// Async thunk for creating a member update
export const createMemberUpdate = createAsyncThunk<
  Update,
  Omit<Update, "id" | "createdAt" | "authorId" | "authorName" | "clientId">,
  { rejectValue: string; state: RootState }
>(
  "memberUpdates/createUpdate",
  async (newUpdate, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.post("/updates/member", newUpdate, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create member update"
      );
    }
  }
);

// Async thunk for updating a member update
export const updateMemberUpdate = createAsyncThunk<
  Update,
  { id: string; updatedUpdate: Partial<Update> },
  { rejectValue: string; state: RootState }
>(
  "memberUpdates/updateUpdate",
  async ({ id, updatedUpdate }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.patch(
        `/updates/member/${id}`,
        updatedUpdate,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update member update"
      );
    }
  }
);

// Async thunk for deleting a member update
export const deleteMemberUpdate = createAsyncThunk<
  string,
  string,
  { rejectValue: string; state: RootState }
>("memberUpdates/deleteUpdate", async (id, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.delete(`/updates/member/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return id;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete member update"
    );
  }
});

// Define the slice
const memberUpdateSlice = createSlice({
  name: "memberUpdates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Member Updates
      .addCase(fetchMemberUpdates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMemberUpdates.fulfilled, (state, action) => {
        state.updates = action.payload;
        state.loading = false;
      })
      .addCase(fetchMemberUpdates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create Member Update
      .addCase(createMemberUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createMemberUpdate.fulfilled, (state, action) => {
        state.updates.push(action.payload);
        state.loading = false;
      })
      .addCase(createMemberUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Update Member Update
      .addCase(updateMemberUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMemberUpdate.fulfilled, (state, action) => {
        // const index = state.updates.findIndex(
        //   (update) => update.id === action.payload.id
        // );
        // if (index !== -1) {
        //   state.updates[index] = action.payload;
        // }
        state.loading = false;
      })
      .addCase(updateMemberUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete Member Update
      .addCase(deleteMemberUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteMemberUpdate.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
        }
      )
      .addCase(deleteMemberUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default memberUpdateSlice.reducer;
