import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

export interface Note {
  id: string;
  clientId: string;
  title: string;
  description: string;
  tags?: string[];
  highImportance?: boolean;
  isPinned?: boolean;
}

interface NotesState {
  notes: Note[];
  loading: boolean;
  error: string | null;
}

const initialState: NotesState = {
  notes: [],
  loading: false,
  error: null,
};

// Async thunk for fetching notes for a client
export const fetchNotesForClient = createAsyncThunk<
  Note[],
  void, // No parameter needed
  { rejectValue: string; state: RootState }
>("notes/fetchNotesForClient", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get(`/notes/client/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error);

    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch notes"
    );
  }
});

// Async thunk for creating a note
export const createNote = createAsyncThunk<
  Note,
  Omit<Note, "id">,
  { rejectValue: string; state: RootState }
>("notes/createNote", async (noteData, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.post("/notes", noteData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to create note"
    );
  }
});

// Async thunk for updating a note
export const updateNote = createAsyncThunk<
  Note,
  { id: string; updateData: Partial<Note> },
  { rejectValue: string; state: RootState }
>(
  "notes/updateNote",
  async ({ id, updateData }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.patch(`/notes/${id}`, updateData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update note"
      );
    }
  }
);

// Async thunk for deleting a note
export const deleteNote = createAsyncThunk<
  { id: string },
  string, // noteId
  { rejectValue: string; state: RootState }
>("notes/deleteNote", async (id, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.delete(`/notes/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { id };
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete note"
    );
  }
});

// Async thunk for toggling the pinned status of a note
export const togglePinStatus = createAsyncThunk<
  Note,
  { id: string; isPinned: boolean },
  { rejectValue: string; state: RootState }
>(
  "notes/togglePinStatus",
  async ({ id, isPinned }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      console.log(id, isPinned);

      const response = await axiosInstance.put(
        `/notes/${id}/pin`,
        { isPinned },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);

      return rejectWithValue(
        error.response?.data?.message || "Failed to update pin status"
      );
    }
  }
);

// Notes slice
const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotesForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchNotesForClient.fulfilled,
        (state, action: PayloadAction<Note[]>) => {
          state.notes = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchNotesForClient.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || null;
        }
      )
      .addCase(createNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNote.fulfilled, (state, action: PayloadAction<Note>) => {
        state.notes.push(action.payload);
        state.loading = false;
      })
      .addCase(
        createNote.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || null;
        }
      )
      .addCase(updateNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNote.fulfilled, (state, action: PayloadAction<Note>) => {
        const index = state.notes.findIndex(
          (note) => note.id === action.payload.id
        );
        if (index !== -1) {
          state.notes[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(
        updateNote.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || null;
        }
      )
      .addCase(deleteNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteNote.fulfilled,
        (state, action: PayloadAction<{ id: string }>) => {
          state.notes = state.notes.filter(
            (note) => note.id !== action.payload.id
          );
          state.loading = false;
        }
      )
      .addCase(
        deleteNote.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || null;
        }
      )
      .addCase(togglePinStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        togglePinStatus.fulfilled,
        (state, action: PayloadAction<Note>) => {
          const index = state.notes.findIndex(
            (note) => note.id === action.payload.id
          );
          if (index !== -1) {
            state.notes[index].isPinned = action.payload.isPinned;
          }
          state.loading = false;
        }
      )
      .addCase(
        togglePinStatus.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || null;
        }
      );
  },
});

export const selectNotes = (state: RootState) => state.notes.notes;
export const selectNotesLoading = (state: RootState) => state.notes.loading;
export const selectNotesError = (state: RootState) => state.notes.error;

export default notesSlice.reducer;
