import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";
import { Department } from "./departmentSlice";
interface Client {
  id: string;
  businessName: string;

  name: string;
  email: string;
  password: string;
  timezone: string;
  description?: string;
  logo?: string;
  values?: string[];
  createdAt: string;
  clientConsultants?: any[]; // Define the shape of clientConsultants
  assignedMembers?: any[]; // Define the shape of assignedMembers
  departments?: Department[];
  _count: {
    clientConsultants: number;
    assignedMembers: number;
  };
}

interface ClientState {
  clients: Client[];
  clientDetails: Client | null;
  loading: boolean;
  error: string | null;
}

const initialState: ClientState = {
  clients: [],
  clientDetails: null,
  loading: false,
  error: null,
};

// Async thunk to handle fetching all clients
export const fetchClients = createAsyncThunk(
  "clients/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/clients");
      console.log("🚀 ~ response:", response);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

// Async thunk to handle fetching client details
export const fetchClientDetails = createAsyncThunk(
  "clients/fetchDetails",
  async (clientId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/clients/${clientId}/details`);
      return response.data;
      console.log("🚀 ~ response:", response);
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

export const createClient = createAsyncThunk(
  "clients/create",
  async (
    payload: FormData, // Update the payload to FormData
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post("/clients", payload, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type for FormData
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Creation failed"
      );
    }
  }
);
export const updateClient = createAsyncThunk(
  "clients/update",
  async (
    payload: FormData, // Update the payload to FormData
    { rejectWithValue }
  ) => {
    try {
      const id = payload.get("id"); // Extract the client ID from FormData
      payload.delete("id"); // Remove the ID from the form data before sending

      const response = await axiosInstance.patch(`/clients/${id}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the correct content type for FormData
        },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Update failed");
    }
  }
);

// Async thunk to handle deleting a client
export const deleteClient = createAsyncThunk(
  "clients/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/clients/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Deletion failed"
      );
    }
  }
);

const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchClients.fulfilled,
        (state, action: PayloadAction<Client[]>) => {
          state.clients = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchClients.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createClient.fulfilled,
        (state, action: PayloadAction<Client>) => {
          state.clients.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(createClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateClient.fulfilled,
        (state, action: PayloadAction<Client>) => {
          const index = state.clients.findIndex(
            (client) => client.id === action.payload.id
          );
          if (index !== -1) {
            state.clients[index] = action.payload;
          }
          state.loading = false;
        }
      )
      .addCase(updateClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteClient.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.clients = state.clients.filter(
            (client) => client.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(deleteClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchClientDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchClientDetails.fulfilled,
        (state, action: PayloadAction<Client>) => {
          state.clientDetails = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchClientDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default clientSlice.reducer;
