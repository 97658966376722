// ! Top Level App Component

import { ChakraProvider } from "@chakra-ui/react"; // ! Initialises Chakra data into website i.e., Tokens

import React, { Suspense } from "react";
import RenderRouter from "./routes/routes";
import { Provider } from "react-redux";
// ! Access export const persistor from store.ts
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "store/store";
import LoadingScreen from "components/LoadingScreen";
import { TimerProvider } from "context/TimerProvider";
import theme from "theme";
function App() {
  return (
    <Provider store={store}>
      {/* <WebSocketProvider> */}
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <Suspense fallback={<div></div>}>
            <TimerProvider>
              <RenderRouter />
            </TimerProvider>
          </Suspense>
        </ChakraProvider>
      </PersistGate>
      {/* </WebSocketProvider> */}
    </Provider>
  );
}

export default App;
