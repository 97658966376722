// src/store/slices/sessionDetailsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";
import { Task } from "types/task";

interface TaskState {
  tasks: Task[];
  loading: boolean;
  error: string | null;
}

const initialState: TaskState = {
  tasks: [],
  loading: false,
  error: null,
};

export const createTaskForMember = createAsyncThunk<
  Task,
  Omit<Task, "id">,
  { rejectValue: string; state: RootState }
>("tasks/createTaskForMember", async (task, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.post("/tasks/createForMember", task, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to create Task"
    );
  }
});

export const createTaskForClient = createAsyncThunk<
  Task,
  Omit<Task, "id">,
  { rejectValue: string; state: RootState }
>("tasks/createTaskForClient", async (task, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.post("/tasks/createForClient", task, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to create Task"
    );
  }
});

export const fetchTasksForMember = createAsyncThunk<
  Task[],
  void,
  { rejectValue: string; state: RootState }
>("tasks/fetchTasksForMember", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/tasks?role=member");
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch tasks for member"
    );
  }
});

export const fetchTasksForClient = createAsyncThunk<
  Task[],
  void,
  { rejectValue: string; state: RootState }
>("tasks/fetchTasksForClient", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/tasks?role=client", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch tasks for client"
    );
  }
});

export const updateTask = createAsyncThunk<
  Task,
  { id: string; task: Partial<Task> },
  { rejectValue: string; state: RootState }
>("tasks/updateTask", async ({ id, task }, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.put(`/tasks/${id}`, task, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to update Task"
    );
  }
});

export const deleteTask = createAsyncThunk<
  string,
  string,
  { rejectValue: string; state: RootState }
>("tasks/deleteTask", async (id, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.delete(`/tasks/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return id;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete Task"
    );
  }
});

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasksForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTasksForMember.fulfilled,
        (state, action: PayloadAction<Task[]>) => {
          state.tasks = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchTasksForMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchTasksForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTasksForClient.fulfilled,
        (state, action: PayloadAction<Task[]>) => {
          state.tasks = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchTasksForClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createTaskForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createTaskForMember.fulfilled,
        (state, action: PayloadAction<Task>) => {
          state.tasks.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(createTaskForMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createTaskForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createTaskForClient.fulfilled,
        (state, action: PayloadAction<Task>) => {
          // state.tasks.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(createTaskForClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTask.fulfilled, (state, action: PayloadAction<Task>) => {
        state.loading = false;
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTask.fulfilled, (state, action: PayloadAction<string>) => {
        // state.tasks = state.tasks.filter((task) => task.id !== intaction.payload);
        state.loading = false;
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default taskSlice.reducer;
