import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

interface ConsultantType {
  id: string;
  name: string;
  description?: string;
}

interface ConsultantTypeState {
  consultantTypes: ConsultantType[];
  loading: boolean;
  error: string | null;
}

const initialState: ConsultantTypeState = {
  consultantTypes: [],
  loading: false,
  error: null,
};

// Async thunk to handle fetching all consultant types
export const fetchConsultantTypes = createAsyncThunk(
  "consultantTypes/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/consultant-types");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

// Async thunk to handle creating a new consultant type
export const createConsultantType = createAsyncThunk(
  "consultantTypes/create",
  async (
    payload: { name: string; description: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post("/consultant-types", payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Creation failed"
      );
    }
  }
);

// Async thunk to handle updating a consultant type
export const updateConsultantType = createAsyncThunk(
  "consultantTypes/update",
  async (
    payload: { id: string; name?: string; description?: string },
    { rejectWithValue }
  ) => {
    try {
      const { id, ...updateData } = payload;
      const response = await axiosInstance.patch(
        `/consultant-types/${id}`,
        updateData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Update failed");
    }
  }
);

// Async thunk to handle deleting a consultant type
export const deleteConsultantType = createAsyncThunk(
  "consultantTypes/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/consultant-types/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Deletion failed"
      );
    }
  }
);

const consultantTypeSlice = createSlice({
  name: "consultantTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultantTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchConsultantTypes.fulfilled,
        (state, action: PayloadAction<ConsultantType[]>) => {
          state.consultantTypes = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchConsultantTypes.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createConsultantType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createConsultantType.fulfilled,
        (state, action: PayloadAction<ConsultantType>) => {
          state.consultantTypes.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(
        createConsultantType.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(updateConsultantType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateConsultantType.fulfilled,
        (state, action: PayloadAction<ConsultantType>) => {
          const index = state.consultantTypes.findIndex(
            (consultantType) => consultantType.id === action.payload.id
          );
          if (index !== -1) {
            state.consultantTypes[index] = action.payload;
          }
          state.loading = false;
        }
      )
      .addCase(
        updateConsultantType.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(deleteConsultantType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteConsultantType.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.consultantTypes = state.consultantTypes.filter(
            (consultantType) => consultantType.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(
        deleteConsultantType.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default consultantTypeSlice.reducer;
