import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    blue: {
      100: "#3ab1d8",
      200: "#3ab1d8",
      300: "#3ab1d8",
      400: "#3ab1d8",
      500: "#3ab1d8",
      600: "#3ab1d8",
      700: "#3ab1d8",
      800: "#3ab1d8",
      900: "#3ab1d8",
    },
  },
  fonts: {
    heading: "Avenir, sans-serif",
    subHeading: "Avenir, sans-serif",
    body: "Avenir, sans-serif",
  },
});

export default theme;
