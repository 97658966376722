import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import LoadingScreen from "components/LoadingScreen";

const ConsultantLogin = lazy(
  () => import("../containers/login/ConsultantLogin")
);
const SysadminLogin = lazy(() => import("../containers/login/SysadminLogin"));
const ClientLogin = lazy(() => import("../containers/login/ClientLogin"));
const MemberLogin = lazy(() => import("../containers/login/MemberLogin"));
const PasswordReset = lazy(() => import("../containers/login/PasswordReset"));

const AdminDashboard = lazy(() => import("../containers/AdminPortal/index"));
const AdminLayout = lazy(
  () => import("../containers/AdminPortal/layout/index")
);

const ConsultantLayout = lazy(
  () => import("../containers/ConsultantPortal/layout/index")
);
const ConsultantDashboard = lazy(
  () => import("../containers/ConsultantPortal/index")
);

// Admin Portal Pages
const AdminClients = lazy(
  () => import("../containers/AdminPortal/pages/clients")
);
const AdminConsultants = lazy(
  () => import("../containers/AdminPortal/pages/consultants")
);

const ClientLayout = lazy(
  () => import("../containers/ClientPortal/layout/index")
);
const ClientDashboard = lazy(() => import("../containers/ClientPortal/index"));

const MemberLayout = lazy(
  () => import("../containers/MemberPortal/layout/index")
);
const MemberDashboard = lazy(() => import("../containers/MemberPortal/index"));
const MemberIssues = lazy(
  () => import("../containers/MemberPortal/pages/Issues")
);

// ! Client Pages
const ClientUpdatesPage = lazy(
  () => import("../containers/ClientPortal/pages/Updates")
);

const ClientScorecardPage = lazy(
  () => import("../containers/ClientPortal/pages/Scoreboard")
);

const ClientRocksPage = lazy(
  () => import("../containers/ClientPortal/pages/Rocks")
);

const ClientTasksPage = lazy(
  () => import("../containers/ClientPortal/pages/Tasks")
);

const ClientIssuesPage = lazy(
  () => import("../containers/ClientPortal/pages/Issues")
);

const ClientSummaryPage = lazy(
  () => import("../containers/ClientPortal/pages/Summary")
);

const ClientFilePage = lazy(
  () => import("../containers/ClientPortal/pages/Files")
);
const ClientNotesPage = lazy(
  () => import("../containers/ClientPortal/pages/Notes")
);
const ClientTeamPage = lazy(
  () => import("../containers/ClientPortal/pages/Team")
);

// ! Member Pages
const MemberUpdatesPage = lazy(
  () => import("../containers/MemberPortal/pages/Updates")
);
const MemberTaskPage = lazy(
  () => import("../containers/MemberPortal/pages/Tasks")
);

const MemberScoreboard = lazy(
  () => import("../containers/MemberPortal/pages/Scoreboard")
);

// ! Consultant pages
const ConsultantClientPage = lazy(
  () => import("../containers/ConsultantPortal/pages/clients")
);
const RenderRouter: React.FC = () => {
  const { role } = useSelector((state: RootState) => state.auth);

  const routes = [
    {
      path: "/",
      element: <Navigate to="/consultant/login" replace />,
    },
    {
      path: "/reset-password",
      element: (
        <PublicRoute>
          <PasswordReset />
        </PublicRoute>
      ),
    },
    {
      path: "/consultant/login",
      element: (
        // <Suspense fallback={<LoadingScreen />}>
        <PublicRoute>
          <ConsultantLogin />
        </PublicRoute>
        // </Suspense>
      ),
    },
    {
      path: "/member/login",
      element: (
        // <Suspense fallback={<LoadingScreen />}>
        <PublicRoute>
          <MemberLogin />
        </PublicRoute>
        // </Suspense>
      ),
    },
    {
      path: "/admin/login",
      element: (
        // <Suspense fallback={<LoadingScreen />}>
        <PublicRoute>
          <SysadminLogin />
        </PublicRoute>
        // </Suspense>
      ),
    },
    {
      path: "/client/login",
      element: (
        // <Suspense fallback={<LoadingScreen />}>
        <PublicRoute>
          <ClientLogin />
        </PublicRoute>
        // </Suspense>
      ),
    },
    {
      path: "/admin/*",
      element: (
        <ProtectedRoute requiredRole="sysadmin">
          <AdminLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "",
          element: <AdminClients />,
        },
        {
          path: "clients",
          element: <AdminClients />,
        },
        {
          path: "consultants",
          element: <AdminConsultants />,
        },
        // Add more admin routes here
      ],
    },
    {
      path: "/consultant/*",
      element: (
        <ProtectedRoute requiredRole="consultant">
          <ConsultantLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "",
          element: <ConsultantDashboard />,
        },
        {
          path: "clients",
          element: <ConsultantClientPage />,
        },
      ],
    },
    {
      path: "/client/*",
      element: (
        <ProtectedRoute requiredRole="client">
          <ClientLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: <ClientDashboard />,
        },
        {
          path: "updates",
          element: <ClientUpdatesPage />,
        },
        {
          path: "scoreboard",
          element: <ClientScorecardPage />,
        },
        {
          path: "anchors",
          element: <ClientRocksPage />,
        },
        {
          path: "tasks",
          element: <ClientTasksPage />,
        },
        {
          path: "issues",
          element: <ClientIssuesPage />,
        },
        {
          path: "summary",
          element: <ClientSummaryPage />,
        },
        {
          path: "files",
          element: <ClientFilePage />,
        },
        {
          path: "notes",
          element: <ClientNotesPage />,
        },
        {
          path: "team",
          element: <ClientTeamPage />,
        },
      ],
    },
    {
      path: "/member/*",
      element: (
        <ProtectedRoute requiredRole="member">
          <MemberLayout />
        </ProtectedRoute>
      ),
      children: [
        // {
        //   path: "dashboard",
        //   element: <MemberDashboard />,
        // },
        {
          path: "updates",
          element: <MemberUpdatesPage />,
        },
        {
          path: "tasks",
          element: <MemberTaskPage />,
        },
        {
          path: "issues",
          element: <MemberIssues />,
        },

        {
          path: "scoreboard",
          element: <MemberScoreboard />,
        },
      ],
    },
  ];

  const element = useRoutes(routes);

  return element;
};

export default RenderRouter;
