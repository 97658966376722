import axios, { AxiosInstance } from "axios";
import store from "store/store";

// Create an Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`, // Add the /api prefix to the base URL
  // baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`, // Add the /api prefix to the base URL
});

// Add a request interceptor to include the token if it exists
axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setBaseURL = (url: string) => {
  axiosInstance.defaults.baseURL = `${url}/api`; // Ensure /api is included in the base URL
};

export default axiosInstance;
