// src/store/sessionDetailsSlice.js
import { RootState } from "store/store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";

// src/types/SessionDetail.ts

export interface Sections {
  segue: number;
  updates: number;
  scorecard: number;
  goals: number;
  tasks: number;
  issues: number;
  summary: number;
}

export interface CreateSessionDetailPayload {
  sections: Sections;
  notes: string;
  rating: number;
  clientId: string;
  consultantId: string;
}

export interface SessionDetailState {
  sessionDetail: CreateSessionDetailPayload | null;
  loading: boolean;
  error: string | null;
}

export const createSessionDetail = createAsyncThunk<
  any, // Adjust this type to your actual response type
  CreateSessionDetailPayload,
  { state: RootState; rejectValue: string }
>(
  "sessionDetails/createSessionDetail",
  async (
    {
      sections,
      rating,
      clientId,
      consultantId,
      notes,
    }: CreateSessionDetailPayload,
    { getState, rejectWithValue }
  ) => {
    const { token } = getState().auth;
    console.log(clientId);
    console.log(consultantId);
    try {
      const response = await axiosInstance.post(
        `/session-details/create/${consultantId}`,
        { sections, rating, clientId, notes },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Failed to send request");
    }
  }
);

const initialState: SessionDetailState = {
  sessionDetail: null,
  loading: false,
  error: null,
};

const sessionDetailsSlice = createSlice({
  name: "sessionDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSessionDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSessionDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionDetail = action.payload;
      })
      .addCase(createSessionDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default sessionDetailsSlice.reducer;
