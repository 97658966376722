import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";

export interface Rock {
  id: string | null;
  title: string;
  type: "NINETY_DAY" | "ONE_YEAR" | "COMPANY_GOAL";
  ownerIds?: string[];
  members?: { id: string; name: string }[];
  companyId: string;
  notes?: string;
  forAll?: boolean;
  status: "ON_TRACK" | "OFF_TRACK" | "COMPLETED" | "ON_HOLD";
  deadline: string;
  createdAt: Date;
  finishedAt: Date | null;
}

interface RockState {
  rocks: Rock[];
  loading: boolean;
  error: string | null;
}

const initialState: RockState = {
  rocks: [],
  loading: false,
  error: null,
};

export const createRock = createAsyncThunk<
  Rock,
  Omit<Rock, "id" | "finishedAt">, // Omit ID and FinishedAt as server-side created
  { rejectValue: string; state: RootState }
>("rocks/createRock", async (rock, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.post("/rocks", rock, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to create Rock"
    );
  }
});

export const fetchRocks = createAsyncThunk<
  Rock[],
  void,
  { rejectValue: string; state: RootState }
>("rocks/fetchRocks", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/rocks", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch rocks"
    );
  }
});

export const updateRock = createAsyncThunk<
  Rock,
  { id: string; rock: Omit<Rock, "finishedAt"> }, // Omit finishedAt
  { rejectValue: string; state: RootState }
>("rocks/updateRock", async ({ id, rock }, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  console.log("rock", rock.forAll);
  try {
    const response = await axiosInstance.put(`/rocks/${id}`, rock, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to update Rock"
    );
  }
});

export const deleteRock = createAsyncThunk<
  string,
  string,
  { rejectValue: string; state: RootState }
>("rocks/deleteRock", async (id, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.delete(`/rocks/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return id;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete rock"
    );
  }
});

const rockSlice = createSlice({
  name: "rocks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRocks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRocks.fulfilled, (state, action: PayloadAction<Rock[]>) => {
        state.rocks = action.payload;
        state.loading = false;
      })
      .addCase(fetchRocks.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createRock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRock.fulfilled, (state, action: PayloadAction<Rock>) => {
        // state.rocks.push(action.payload);
        state.loading = false;
      })
      .addCase(createRock.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateRock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRock.fulfilled, (state, action: PayloadAction<Rock>) => {
        // const index = state.rocks.findIndex(
        //   (rock) => rock.id === action.payload.id
        // );
        // if (index !== -1) {
        //   state.rocks[index] = action.payload;
        // }
        state.loading = false;
      })
      .addCase(updateRock.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteRock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRock.fulfilled, (state, action: PayloadAction<string>) => {
        // state.rocks = state.rocks.filter((rock) => rock.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteRock.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default rockSlice.reducer;
