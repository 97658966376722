import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { validateToken } from "store/slices/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

interface ProtectedRouteProps {
  requiredRole?: string;
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requiredRole,
  children,
}) => {
  const { token, role, user, validatingToken, loginComplete } = useSelector(
    (state: RootState) => state.auth
  );
  console.log(token);
  console.log(role);
  console.log(user);
  console.log(loginComplete);
  console.log(validatingToken);
  console.log(requiredRole);
  const location = useLocation();

  console.log(loginComplete);

  if (!token) {
    console.log("No token found, redirecting to login");
    return <Navigate to="/admin/login" state={{ from: location }} />;
  }

  if (requiredRole && role !== requiredRole) {
    console.log(`Required role ${requiredRole} not met, redirecting to login`);
    return <Navigate to="/admin/login" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
