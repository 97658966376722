import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";

export interface Goal {
  id: string;
  name: string;
  measurable: string;
  departmentId: string;
  clientId: string;
  memberId?: string;
  memberIds?: string[];
  primaryType: string;
  secondaryType: string;
  measurableValue: string[];
  department?: {
    id: string;
    name: string;
  };
  member?: {
    id: string;
    name: string;
  };
  client?: {
    id: string;
    name: string;
  };
  members?: {
    id: string;
    name: string;
  }[]; // Added to include an array of member objects
}

interface GoalState {
  goals: Goal[];
  loading: boolean;
  error: string | null;
}

const initialState: GoalState = {
  goals: [],
  loading: false,
  error: null,
};

// Async thunk for fetching goals
export const fetchGoals = createAsyncThunk<
  Goal[],
  void,
  { rejectValue: string; state: RootState }
>("goals/fetchGoals", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/goals/client", {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error);

    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch goals"
    );
  }
});

export const fetchGoalsForMember = createAsyncThunk<
  Goal[],
  void,
  { rejectValue: string; state: RootState }
>("goals/fetchGoalsForMember", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/goals/member", {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error);

    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch goals"
    );
  }
});

// Async thunk for creating a goal
export const createGoal = createAsyncThunk<
  Goal,
  Omit<Goal, "id" | "department" | "member">,
  { rejectValue: string; state: RootState }
>(
  "goals/createGoal",
  async (newGoal, { getState, rejectWithValue, dispatch }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.post("/goals", newGoal, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(fetchGoals()); // Fetch goals after creating a new goal
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create goal"
      );
    }
  }
);

// Async thunk for updating a goal
export const updateGoal = createAsyncThunk<
  Goal,
  { id: string; updatedGoal: Partial<Goal> },
  { rejectValue: string; state: RootState }
>(
  "goals/updateGoal",
  async ({ id, updatedGoal }, { getState, rejectWithValue, dispatch }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.patch(`/goals/${id}`, updatedGoal, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(fetchGoals()); // Fetch goals after updating a goal
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update goal"
      );
    }
  }
);

// Async thunk for deleting a goal
export const deleteGoal = createAsyncThunk<
  string,
  string,
  { rejectValue: string; state: RootState }
>("goals/deleteGoal", async (id, { getState, rejectWithValue, dispatch }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.delete(`/goals/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(fetchGoals()); // Fetch goals after deleting a goal
    return id;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete goal"
    );
  }
});

// Define the slice
const goalSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGoals.fulfilled, (state, action: PayloadAction<Goal[]>) => {
        state.loading = false;
        state.goals = action.payload;
      })
      .addCase(fetchGoals.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchGoalsForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchGoalsForMember.fulfilled,
        (state, action: PayloadAction<Goal[]>) => {
          state.loading = false;
          state.goals = action.payload;
        }
      )
      .addCase(
        fetchGoalsForMember.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createGoal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGoal.fulfilled, (state, action: PayloadAction<Goal>) => {
        state.loading = false;
        // State update is handled by fetchGoals, so no need to push new goal
      })
      .addCase(createGoal.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateGoal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGoal.fulfilled, (state, action: PayloadAction<Goal>) => {
        state.loading = false;
        // State update is handled by fetchGoals, so no need to update goal here
      })
      .addCase(updateGoal.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteGoal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGoal.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        // State update is handled by fetchGoals, so no need to remove goal here
      })
      .addCase(deleteGoal.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default goalSlice.reducer;
