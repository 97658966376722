import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";

// Override console methods to suppress all console logs
const noop = () => {};
console.log = noop;
console.warn = noop;
console.error = noop;
console.info = noop;
console.debug = noop;

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as HTMLElement);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
