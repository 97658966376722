import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";

export interface NotificationDetails {
  id: string;
  type: string;
  message: string;
  createdAt: string;
  key: string;
  viewed: boolean;
}

// Initial State Interface
interface NotificationState {
  notifications: NotificationDetails[];
  fetchStatus: string;
  markAsViewedStatus: string;
  error: string | null;
}

const initialState: NotificationState = {
  notifications: [],
  fetchStatus: "idle",
  markAsViewedStatus: "idle",
  error: null,
};

// Async thunk for fetching notifications
export const fetchNotifications = createAsyncThunk<
  NotificationDetails[],
  void,
  { rejectValue: string; state: RootState }
>(
  "notifications/fetchNotifications",
  async (_, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.get(`/notification`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      console.log(error);

      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch notifications"
      );
    }
  }
);

export const markAsViewed = createAsyncThunk<
  void,
  void,
  { rejectValue: string; state: RootState }
>("notifications/markAsViewed", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.put(`/notification/mark-all-viewed`);
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to mark notifications as viewed"
    );
  }
});
const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.fetchStatus = "loading";
        state.error = null;
      })
      .addCase(
        fetchNotifications.fulfilled,
        (state, action: PayloadAction<NotificationDetails[]>) => {
          state.fetchStatus = "succeeded";
          state.notifications = action.payload;
        }
      )
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.error = action.payload ?? "Failed to Fetch Notifications";
      })
      .addCase(markAsViewed.pending, (state) => {
        state.markAsViewedStatus = "loading";
        state.error = null;
      })
      .addCase(markAsViewed.fulfilled, (state) => {
        state.markAsViewedStatus = "succeeded";
        state.notifications = state.notifications.map((notification) => ({
          ...notification,
          viewed: true,
        }));
      })
      .addCase(markAsViewed.rejected, (state, action) => {
        state.markAsViewedStatus = "failed";
        state.error = action.payload ?? "Failed to mark as viewed";
      });
  },
});

export default notificationSlice.reducer;
