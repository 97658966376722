import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";
export enum ReportTimeFrame {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  HALF_YEAR = "HALF_YEAR",
  YEAR = "YEAR",
  ALL_TIME = "ALL_TIME",
}

// Define the interface for report details
export interface ReportDetails {
  id: string;
  executiveSummary: string;
  actionItems: any;
  createdAt: string;
  updatedAt: string;
  status: string;
  timeframe: string;
  tags: string[];
}

// Define the initial state interface
interface ReportState {
  reports: ReportDetails[];
  fetchStatus: string;
  createStatus: string;
  error: string | null;
}

// Define the initial state
const initialState: ReportState = {
  reports: [],
  fetchStatus: "idle",
  createStatus: "idle",
  error: null,
};
// Async thunk for creating a report
export const createReport = createAsyncThunk<
  ReportDetails,
  { timeframe: ReportTimeFrame; tags: string[] },
  { rejectValue: string; state: RootState }
>(
  "reports/createReport",
  async ({ timeframe, tags }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const payload = {
        timeframe: timeframe,
        tags: tags,
      };
      const response = await axiosInstance.post(`/report`, payload);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to create report"
      );
    }
  }
);

// Async thunk for fetching reports
export const fetchReports = createAsyncThunk<
  ReportDetails[],
  void,
  { rejectValue: string; state: RootState }
>("reports/fetchReports", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get(`/report`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch reports"
    );
  }
});

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state) => {
        state.fetchStatus = "loading";
        state.error = null;
      })
      .addCase(
        fetchReports.fulfilled,
        (state, action: PayloadAction<ReportDetails[]>) => {
          state.fetchStatus = "succeeded";
          state.reports = action.payload;
        }
      )
      .addCase(
        fetchReports.rejected,
        (state, action: PayloadAction<string>) => {
          state.fetchStatus = "failed";
          state.error = action.payload;
        }
      )
      .addCase(createReport.pending, (state) => {
        state.createStatus = "loading";
        state.error = null;
      })
      .addCase(
        createReport.fulfilled,
        (state, action: PayloadAction<ReportDetails>) => {
          state.createStatus = "succeeded";
          state.reports.push(action.payload);
        }
      )
      .addCase(
        createReport.rejected,
        (state, action: PayloadAction<string>) => {
          state.createStatus = "failed";
          state.error = action.payload;
        }
      );
  },
});

export default reportSlice.reducer;
