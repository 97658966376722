// store/slices/weeklyScorecardSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";

interface Scorecard {
  goalId: string;
  value: string;
}

interface WeeklyScorecard {
  id: string | null;
  date: Date;
  scorecards: Scorecard[];
}

interface WeeklyScorecardState {
  weeklyScorecards: WeeklyScorecard[];
  loading: boolean;
  error: string | null;
}

const initialState: WeeklyScorecardState = {
  weeklyScorecards: [],
  loading: false,
  error: null,
};

// Async thunk for creating weekly scorecards
export const createWeeklyScorecards = createAsyncThunk<
  WeeklyScorecard[],
  WeeklyScorecard[],
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/createWeeklyScorecards",
  async (weeklyScorecards, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.post(
        "/weekly-scorecards",
        weeklyScorecards,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create weekly scorecards"
      );
    }
  }
);

// Async thunk for updating weekly scorecards
export const updateWeeklyScorecards = createAsyncThunk<
  WeeklyScorecard[],
  WeeklyScorecard[],
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/updateWeeklyScorecards",
  async (weeklyScorecards, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.patch(
        "/weekly-scorecards",
        weeklyScorecards,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update weekly scorecards"
      );
    }
  }
);

// Async thunk for fetching weekly scorecards
export const fetchWeeklyScorecards = createAsyncThunk<
  WeeklyScorecard[],
  void,
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/fetchWeeklyScorecards",
  async (_, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.get("/weekly-scorecards", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch weekly scorecards"
      );
    }
  }
);

const weeklyScorecardSlice = createSlice({
  name: "weeklyScorecards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Weekly Scorecards
      .addCase(fetchWeeklyScorecards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchWeeklyScorecards.fulfilled,
        (state, action: PayloadAction<WeeklyScorecard[]>) => {
          state.weeklyScorecards = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchWeeklyScorecards.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // Create Weekly Scorecards
      .addCase(createWeeklyScorecards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createWeeklyScorecards.fulfilled,
        (state, action: PayloadAction<WeeklyScorecard[]>) => {
          state.weeklyScorecards.push(...action.payload);
          state.loading = false;
        }
      )
      .addCase(
        createWeeklyScorecards.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // Update Weekly Scorecards
      .addCase(updateWeeklyScorecards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateWeeklyScorecards.fulfilled,
        (state, action: PayloadAction<WeeklyScorecard[]>) => {
          state.loading = false;
          const updatedScorecards = action.payload;
          updatedScorecards.forEach((updated) => {
            const index = state.weeklyScorecards.findIndex(
              (sc) => sc.id === updated.id
            );
            if (index !== -1) {
              state.weeklyScorecards[index] = updated;
            }
          });
        }
      )
      .addCase(
        updateWeeklyScorecards.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default weeklyScorecardSlice.reducer;
