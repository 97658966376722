// src/slices/clientUpdateSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

interface Update {
  id: string;
  type: string;
  content: string;
  authorId: string;
  authorName: string; // Added field for author name
  clientId: string;
  createdAt: string;
}

interface UpdateState {
  updates: Update[];
  loading: boolean;
  error: string | null;
}

const initialState: UpdateState = {
  updates: [],
  loading: false,
  error: null,
};

// Async thunk for fetching client updates
export const fetchClientUpdates = createAsyncThunk<
  Update[],
  void,
  { rejectValue: string; state: RootState }
>("clientUpdates/fetchUpdates", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/updates/client", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch client updates"
      );
    }
    return rejectWithValue("Failed to send request");
  }
});

// Async thunk for creating a client update
export const createClientUpdate = createAsyncThunk<
  Update,
  Omit<Update, "id" | "createdAt" | "authorId" | "authorName" | "clientId">,
  { rejectValue: string; state: RootState }
>(
  "clientUpdates/createUpdate",
  async (newUpdate, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.post("/updates/client", newUpdate, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create client update"
      );
    }
  }
);

// Async thunk for updating a client update
export const updateClientUpdate = createAsyncThunk<
  Update,
  { id: string; updatedUpdate: Partial<Update> },
  { rejectValue: string; state: RootState }
>(
  "clientUpdates/updateUpdate",
  async ({ id, updatedUpdate }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.patch(
        `/updates/client/${id}`,
        updatedUpdate,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update client update"
      );
    }
  }
);

// Async thunk for deleting a client update
export const deleteClientUpdate = createAsyncThunk<
  string,
  string,
  { rejectValue: string; state: RootState }
>("clientUpdates/deleteUpdate", async (id, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    await axiosInstance.delete(`/updates/client/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return id;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete client update"
    );
  }
});

// Define the slice
const clientUpdateSlice = createSlice({
  name: "clientUpdates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Client Updates
      .addCase(fetchClientUpdates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClientUpdates.fulfilled, (state, action) => {
        state.updates = action.payload;
        state.loading = false;
      })
      .addCase(fetchClientUpdates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create Client Update
      .addCase(createClientUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createClientUpdate.fulfilled, (state, action) => {
        // state.updates.push(action.payload);
        state.loading = false;
      })
      .addCase(createClientUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Update Client Update
      .addCase(updateClientUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateClientUpdate.fulfilled, (state, action) => {
        // const index = state.updates.findIndex(
        //   (update) => update.id === action.payload.id
        // );
        // if (index !== -1) {
        //   state.updates[index] = action.payload;
        // }
        state.loading = false;
      })
      .addCase(updateClientUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete Client Update
      .addCase(deleteClientUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteClientUpdate.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.updates = state.updates.filter(
            (update) => update.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(deleteClientUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default clientUpdateSlice.reducer;
