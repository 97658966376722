import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface TimerContextProps {
  timers: Record<string, number>;
  startTimer: (navItem: string) => void;
  stopTimer: () => void;
  resetTimers: () => void;
  isRunning: boolean;
  currentNav: string;
}

interface TimerProviderProps {
  children: ReactNode; // Explicitly define the type for children
}

const TimerContext = createContext<TimerContextProps | undefined>(undefined);

export const useTimer = (): TimerContextProps => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error("useTimer must be used within a TimerProvider");
  }
  return context;
};

export const TimerProvider: React.FC<TimerProviderProps> = ({ children }) => {
  const [timers, setTimers] = useState<Record<string, number>>({});
  const [currentNav, setCurrentNav] = useState<string>("");
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isRunning && currentNav) {
      interval = setInterval(() => {
        setTimers((prevTimers) => ({
          ...prevTimers,
          [currentNav]: (prevTimers[currentNav] || 0) + 1,
        }));
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRunning, currentNav]);

  const resetTimers = () => {
    setTimers({});
  };

  const startTimer = (navItem: string) => {
    console.log("Start");
    console.log(navItem);
    setCurrentNav(navItem);
    setIsRunning(true);
  };

  const stopTimer = () => {
    console.log("Stop");
    setIsRunning(false);
  };

  return (
    <TimerContext.Provider
      value={{
        timers,
        startTimer,
        stopTimer,
        resetTimers, // Ensure resetTimers is part of the provided context
        isRunning,
        currentNav,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
