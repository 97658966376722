import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";

// Define the interface for file details
export interface FileDetails {
  key: string;
  name: string;
  size: number;
  lastModified: string;
  url: string;
}

// Define the initial state interface
interface FileState {
  files: FileDetails[];
  uploadStatus: string;
  fetchStatus: string;
  deleteStatus: string;
  error: string | null;
}

// Define the initial state
const initialState: FileState = {
  files: [],
  uploadStatus: "idle",
  fetchStatus: "idle",
  deleteStatus: "idle",
  error: null,
};

// Async thunk for uploading a file
export const uploadFile = createAsyncThunk<
  FileDetails,
  { file: File; userId: string },
  { rejectValue: string; state: RootState }
>(
  "files/uploadFile",
  async ({ file, userId }, { getState, rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", userId); // Include userId in formData
    formData.append("isPublic", "false"); // Set this as needed

    try {
      const { token } = getState().auth;

      const response = await axiosInstance.post("/dms/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to upload file"
      );
    }
  }
);

// Async thunk for fetching files
export const fetchFiles = createAsyncThunk<
  FileDetails[],
  void,
  { rejectValue: string; state: RootState }
>("files/fetchFiles", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  console.log(token);
  try {
    const response = await axiosInstance.get(`/dms/user/files`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch files"
    );
  }
});

// Async thunk for deleting a file
export const deleteFile = createAsyncThunk<
  string,
  string,
  { rejectValue: string; state: RootState }
>("files/deleteFile", async (key, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    console.log(`Deleting file with key: ${key}`);
    console.log(`DELETE request to: /dms?key=${key}`);

    await axiosInstance.delete(`/dms`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { key }, // Because the key has / it cannot be in the url
    });

    return key;
  } catch (error: any) {
    console.error(error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete file"
    );
  }
});

// Define the slice
const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle uploadFile
    builder
      .addCase(uploadFile.pending, (state) => {
        state.uploadStatus = "loading";
        state.error = null;
      })
      .addCase(
        uploadFile.fulfilled,
        (state, action: PayloadAction<FileDetails>) => {
          state.uploadStatus = "succeeded";
          state.files.push(action.payload);
        }
      )
      .addCase(uploadFile.rejected, (state, action: PayloadAction<string>) => {
        state.uploadStatus = "failed";
        state.error = action.payload;
      });

    // Handle fetchFiles
    builder
      .addCase(fetchFiles.pending, (state) => {
        state.fetchStatus = "loading";
        state.error = null;
      })
      .addCase(
        fetchFiles.fulfilled,
        (state, action: PayloadAction<FileDetails[]>) => {
          state.fetchStatus = "succeeded";
          state.files = action.payload;
        }
      )
      .addCase(fetchFiles.rejected, (state, action: PayloadAction<string>) => {
        state.fetchStatus = "failed";
        state.error = action.payload;
      });

    // Handle deleteFile
    builder
      .addCase(deleteFile.pending, (state) => {
        state.deleteStatus = "loading";
        state.error = null;
      })
      .addCase(deleteFile.fulfilled, (state, action: PayloadAction<string>) => {
        state.deleteStatus = "succeeded";
        state.files = state.files.filter((file) => file.key !== action.payload);
      })
      .addCase(deleteFile.rejected, (state, action: PayloadAction<string>) => {
        state.deleteStatus = "failed";
        state.error = action.payload;
      });
  },
});

export default fileSlice.reducer;
